/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getServicesService,
  deleteServiceService
} from '../../api/services/service-services';
import { sendEmailNotificationStaffService } from '../../api/services/staffs-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import ServiceModal from './ServiceModal';
import ServicesView from './ServicesView';
import Tracking from '../Tracking';

import {
  SERVICE_ITEM_ROUTE,
  SERVICE_HISTORY_ROUTE
} from '../../config/URL_ROUTES';

const ServicesModel = {
  id: null,
  name: '',
  item_number: '',
  client: '',
  phone: null,
  email: '',
  planned_delivery_date: '',
  business_id: null,
  staff_id: null,
  status_id: null,
  token: ''
};

const ServicesComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const getServices = useCallback(() => {
    setLoading(true);
    getServicesService()
      .then((response) => {
        setServices(response?.services);
        setOriginalData(response?.services);
      })
      .catch((error) => {
        console.error(
          'staffsComponent - Error requesting services list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getServices();
  }, [getServices]);

  const handleOnUpdateList = (selectedService) => {
    getServices();
  };

  const handleOnFilterService = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field3 = item.staff_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field4 = item.status_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field5 = item.email
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field6 = item.client
          ?.toString()
          .toLowerCase()
          .includes(filteringText);

        return field3 || field4 || field5 || field6;
      });
    }

    setServices(filteredItems);
  };

  const handleOnAddService = () => {
    setSelectedService(Object.assign({}, ServicesModel));
    setModalMode('services');
  };

  const handleOnEditService = (row) => {
    setSelectedService(row);
    setModalMode('services');
  };

  const handleOnModalClose = () => {
    setSelectedService(null);
    setModalMode(null);
  };

  const handleOnDeleteService = (row) => {
    setSelectedService(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteServiceService({ service_id: selectedService.id })
      .then(() => {
        getServices();
        toast.success('Servicio borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el servicio.');
      })
      .finally(() => {
        setSelectedService(null);
        setModalMode(null);
      });
  };

  const serviceItemRoute = (row) => {
    history.push({
      pathname: SERVICE_ITEM_ROUTE + '/' + row?.id,
      state: {
        id: row?.id,
        title: row?.code,
        client: row?.client
      }
    });
  };

  const handleOnHistoryService = (row) => {
    history.push({
      pathname: SERVICE_HISTORY_ROUTE + '/' + row?.id,
      state: {
        id: row?.id,
        title: row?.code,
        client: row?.client
      }
    });
  };

  const handleOnTrackingGuide = (row) => {
    setModalMode('tracking');
    setSelectedService(row);
  };

  const handleOnSendEmail = (row) => {
    let serviceId = selectedService?.id;
    if (!serviceId) return;
    sendEmailNotificationStaffService({ service_id: serviceId })
      .then(() => {
        getServices();
        toast.success('Email de servicio enviado');
      })
      .catch((error) => {
        toast.error(
          'Error, no fue posible enviar notificacion del servicio al responsable.'
        );
      })
      .finally(() => {
        setSelectedService(null);
        setModalMode(null);
      });
    setSelectedService(row);
  };

  const handleOnNotificationStaff = (row) => {
    setModalMode('sendEmail');
    setSelectedService(row);
  };

  return (
    <>
      <ServicesView
        onServiceItem={serviceItemRoute}
        onHistoryService={handleOnHistoryService}
        loading={loading}
        services={services}
        onTrackingGuide={handleOnTrackingGuide}
        onFilterService={handleOnFilterService}
        onAddService={handleOnAddService}
        onEditService={handleOnEditService}
        onDeleteService={handleOnDeleteService}
        onSendNotification={handleOnNotificationStaff}
      />
      {selectedService && modalMode === 'services' && (
        <ServiceModal
          serviceData={selectedService}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedService && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Partida "
        >
          {'¿Estas seguro que quieres borrar el servicio  "' +
            selectedService.name +
            '"?'}
        </Modal>
      )}
      {selectedService && modalMode === 'tracking' && (
        <Modal
          open={true}
          onConfirm={handleOnModalClose}
          title={'Estatus del servicio: ' + selectedService?.name}
        >
          <Tracking serviceToken={selectedService?.token} />
        </Modal>
      )}
      {selectedService && modalMode === 'sendEmail' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnSendEmail}
          title="Enviar Email"
        >
          {'¿Estas seguro que quieres enviar email con la información del servicio al responsable ' +
            selectedService?.staff_name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ServicesComponent;
