/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from "react";

/** Import helpers section **/
import { toast } from "react-toastify";
import {
  getProfileService,
  changePasswordService,
} from "../../api/services/profile-services";

/** Import resources section **/

/** Import component section **/
import ProfileView from "./ProfileView";
import ProfileModal from "./ProfileModal";

const profileModel = {
  id: null,
  logo_url: null,
  current_password: null,
  new_password: null,
  confirm_password: null,
};

const ProfileComponent = () => {
  const [profile, setProfile] = useState(profileModel);
  const [loading, setLoading] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [validPassword, setValidPassword] = useState(false);

  const getProfile = useCallback(() => {
    setLoading(true);
    console.log("getProfile");
    getProfileService()
      .then((response) => {
        if (response?.profile?.logo_url) {
          setProfile((prevState) => ({
            ...prevState,
            logo_url: response?.profile?.logo_url,
          }));
        }
      })
      .catch((error) => {
        console.error(
          "profileComponent - Error requesting profile list: ",
          error
        );
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const updatePassword = () => {
    console.log(profile);
    setLoading(true);
    let params = {
      current_password: profile?.current_password,
      new_password: profile?.new_password,
    };
    changePasswordService(params)
      .then((response) => {
        toast.success("Contraseña cambiada correctamente.");
      })

      .catch((error) => {
        toast.error("Error al actualizar la contraseña.");
        console.error(
          "profileComponent - Error requesting profile list: ",
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("use effect");
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    let validated = false;
    let currentPassword = profile?.current_password;
    let newPassword = profile?.new_password;
    let confirmPassword = profile?.confirm_password;
    if (
      !!currentPassword &&
      !!newPassword &&
      !!confirmPassword &&
      confirmPassword === newPassword
    ) {
      validated = true;
    }
    setValidPassword(validated);
  }, [
    profile?.current_password,
    profile?.new_password,
    profile?.confirm_password,
  ]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnUpdate = () => {
    getProfile();
  };

  const handleOnModalClose = () => {
    setModalMode(null);
  };

  const handleOnEditProfile = () => {
    setModalMode("profile");
  };

  const handleOnUpdatePassword = () => {
    updatePassword();
  };

  return (
    <>
      <ProfileView
        loading={loading}
        profile={profile}
        onInputChange={handleOnInputChange}
        onEditProfile={handleOnEditProfile}
        onUpdatePassword={handleOnUpdatePassword}
        validPassword={validPassword}
      />

      {modalMode === "profile" && (
        <ProfileModal
          profileData={profile}
          onClose={handleOnModalClose}
          onUpdate={handleOnUpdate}
        />
      )}
    </>
  );
};

export default ProfileComponent;
