/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  AddServiceService,
  updateServiceService
} from '../../../api/services/service-services';

import { getStaffsSelectService } from '../../../api/services/staffs-services';
import { getStatusSelectService } from '../../../api/services/status-services';
import { sendEmailNotificationClientService } from '../../../api/services/service-services';

import Modal from '../../../components/Modal/ModalComponent';

import { isValidEmail } from '../../../utils/validations';

/** Import component section **/
import ServiceModalView from './ServiceModalView';

const ServiceModalComponent = ({ serviceData, onClose, onUpdateList }) => {
  const [service, setService] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [status, setStatus] = useState([]);
  const [dataComplete, setDataComplete] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const getStatus = () => {
    getStatusSelectService()
      .then((response) => {
        if (response?.status) setStatus(response?.status);
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error('No se pueden cargar los responsables');
        console.error('Error getting areas: ', err);
      });
  };

  const getStaffs = () => {
    getStaffsSelectService()
      .then((response) => {
        if (response?.staffs) setStaffs(response?.staffs);
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error('No se pueden cargar los responsables');
        console.error('Error getting areas: ', err);
      });
  };

  useEffect(() => {
    setService(serviceData);
    getStaffs();
    getStatus();
  }, [serviceData]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setService((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setService((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!service) return;
    let validated = true;
    const serviceName = service?.name?.trim();
    const serviceCode = service?.code;
    const servicePhone = service?.phone;
    const serviceEmail = service?.email?.trim();
    const serviceClient = service?.client?.trim();
    const serviceStaffId = service?.staff_id;
    const serviceStatusId = service?.status_id;
    const serviceDeliveryDate = service?.planned_delivery_date;

    if (
      serviceStaffId === null ||
      serviceClient === '' ||
      serviceCode === '' ||
      serviceName === '' ||
      serviceStatusId === null ||
      serviceDeliveryDate === '' ||
      servicePhone === null ||
      !isValidEmail(serviceEmail)
    ) {
      validated = false;
    }
    setDataComplete(validated);
  }, [service]);

  const handleOnSave = () => {
    if (serviceData.id) {
      updateService();
      //validacion de cambio de status antes de pedir la confirmacion para enviar el correo electronico
      if (service.status_id === serviceData.status_id) {
        onClose();
      } else {
        setSendEmail(true);
      }
    } else {
      createService();
    }
  };

  const createService = () => {
    setLoading(true);
    AddServiceService(service)
      .then((response) => {
        toast.success('Servicio creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Service create error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateService = () => {
    setLoading(true);
    updateServiceService(service)
      .then(() => {
        toast.success('Servicio actualizado');
        onUpdateList();
      })
      .catch((error) => {
        toast.warning('Service update error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEmailConfirmation = () => {
    let params = {
      service_id: service?.id
    };
    sendEmailNotificationClientService(params)
      .then(() => {
        toast.success('Correo de actualización enviado');
        onClose();
      })
      .catch((error) => {
        toast.warning('Service email client error');
      });
  };
  return (
    <>
      <ServiceModalView
        service={serviceData}
        staffs={staffs}
        status={status}
        onInputChange={onInputChange}
        onSwitchChange={onSwitchChange}
        onClose={onClose}
        onSave={handleOnSave}
        disabled={!dataComplete}
        loading={loading}
      />
      {sendEmail === true && (
        <Modal
          open={true}
          onClose={onClose}
          onConfirm={onEmailConfirmation}
          title="Actualizacion exitosa"
        >
          {'¿Deseas enviar un email con la informacion actualizada al cliente?'}
        </Modal>
      )}
    </>
  );
};

export default ServiceModalComponent;
