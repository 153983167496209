/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import SingupsComponent from '../views/Signups';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';
import BusinessesComponent from '../views/Businesses';
import SubscriptionsComponent from '../views/Subscriptions';
import StaffsComponent from '../views/Staffs';
import StatusComponent from '../views/Status';
import ProfileComponent from '../views/Profile';
import ServicesComponent from '../views/Services';
import ServiceItemComponent from '../views/ServiceItem';
import ServiceHistoryComponent from '../views/ServiceHistory';
import ServiceItemHistoryComponent from '../views/ServiceItemHistory';
import DashboardComponent from '../views/Dashboard';

import UsersComponent from '../views/Users';
import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';
import TrackingComponent from '../views/Tracking/TrackingComponent';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: LoginComponent
  },
  {
    path: routes.LOGIN_ROUTE,
    component: LoginComponent
  },
  {
    path: routes.SIGNUPS_ROUTE,
    component: SingupsComponent
  },
  {
    path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
    component: EmailValidationComponent
  },
  {
    path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
    component: ChangePasswordRequestComponent
  },
  {
    path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
    component: ChangePasswordComponent
  },
  {
    path: routes.TRACKING_ROUTE + '/:rfc/:token',
    component: TrackingComponent
  }
];

export const PRIVATE_ROUTES = [
  {
    path: routes.SUBSCRIPTIONS_ROUTE,
    component: SubscriptionsComponent
  },
  {
    path: '/',
    component: UsersComponent
  },
  {
    path: routes.ROLE_PERMISSIONS_ROUTE,
    component: RolePermisionsComponent
  },
  {
    path: routes.PERMISSIONS_ROUTE,
    component: PermissionsComponent
  },
  {
    path: routes.USERS_ROUTE,
    component: UsersComponent
  },
  {
    path: routes.BUSSINESES_ROUTE,
    component: BusinessesComponent
  },
  {
    path: routes.STAFFS_ROUTE,
    component: StaffsComponent
  },
  {
    path: routes.STATUS_ROUTE,
    component: StatusComponent
  },
  {
    path: routes.PROFILES_ROUTE,
    component: ProfileComponent
  },
  {
    path: routes.SERVICES_ROUTE,
    component: ServicesComponent
  },
  {
    path: routes.SERVICE_ITEM_ROUTE + '/:id',
    component: ServiceItemComponent
  },
  {
    path: routes.SERVICE_HISTORY_ROUTE + '/:id',
    component: ServiceHistoryComponent
  },
  {
    path: routes.SERVICE_ITEM_HISTORY_ROUTE + '/:id',
    component: ServiceItemHistoryComponent
  },
  {
    path: routes.DASHBOARD_ROUTE,
    component: DashboardComponent
  }
];
