/** Import react/libraries section **/
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/** Import services section **/
import { singupsService } from '../../api/services/signups-services';

/** Import helpers section **/
import { toast } from 'react-toastify';
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import SingupsView from './SignupsView';
import ConfirmModal from '../../components/ConfirmModal';

const SignupsComponent = () => {
  const [inProcess, setInProcess] = useState(false);
  const [registered, setRegistrationed] = useState(false);

  const history = useHistory();

  const handleOnCancel = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };

  const handleOnSubmit = (userData) => {
    setInProcess(true);
    singupsService(userData)
      .then((response) => {
        setRegistrationed(true);
      })
      .catch((err) => {
        if (err?.response_status) toast.error("Can't load information");
        console.error('Error in registration: ', err);
      })
      .finally(() => {
        setInProcess(false);
      });
  };

  const handleOnConfirmModalClose = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };

  return (
    <>
      <SingupsView
        onSubmit={handleOnSubmit}
        onCancel={handleOnCancel}
        inProcess={inProcess}
        registered={registered}
      />
      {registered && (
        <ConfirmModal
          title={'Successfully registered!'}
          message={
            'Se envió un correo electrónico con el enlace de verificación.'
          }
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default SignupsComponent;
