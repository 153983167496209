/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addStaffsService,
  updateStaffsService
} from '../../../api/services/staffs-services';

import { getBusinessSelectService } from '../../../api/services/businesses-services';

import { isValidEmail } from '../../../utils/validations';

/** Import component section **/
import StaffModalView from './StaffModalView';

const StaffModalComponent = ({ staffData, onClose, onUpdateList }) => {
  const [staff, setStaff] = useState(null);
  const [businesses, setBusiness] = useState([]);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    setStaff(staffData);
    getBusiness();
  }, [staffData]);

  const getBusiness = () => {
    getBusinessSelectService()
      .then((response) => {
        if (response?.business) setBusiness(response?.business);
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error('No se pueden cargar los negocios');
        console.error('Error getting areas: ', err);
      });
  };

  const onInputChange = (data) => {
    const { name, value } = data;
    setStaff((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setStaff((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!staff) return;
    let validated = true;
    const staffName = staff?.name?.trim();
    const staffEmail = staff?.email?.trim();
    if (staffEmail === '' || staffName === '' || !isValidEmail(staffEmail)) {
      validated = false;
    }
    setDataComplete(validated);
  }, [staff]);

  const handleOnSave = () => {
    if (staffData.id) {
      updateStaff();
    } else {
      createStaff();
    }
  };

  const createStaff = () => {
    let params = {
      name: staff?.name,
      email: staff?.email
    };
    addStaffsService(params)
      .then((response) => {
        toast.success('Responsable creado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Staff create error');
      });
  };

  const updateStaff = () => {
    let params = {
      id: staff?.id,
      name: staff?.name,
      email: staff?.email
    };
    updateStaffsService(params)
      .then(() => {
        toast.success('Responsable actualizado');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Staff update error');
      });
  };

  return (
    <StaffModalView
      staff={staffData}
      business={businesses}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default StaffModalComponent;
