/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getStatusService,
  deleteStatusService
} from '../../api/services/status-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import StatusModal from './StatusModal';
import StatusView from './StatusView';

const statusModal = {
  id: null,
  title: '',
  color: '',
  sequence: 1
};

const StatusComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStatus = useCallback(() => {
    setLoading(true);
    getStatusService()
      .then((response) => {
        if (response?.status) {
          setStatus(response?.status);
          setOriginalData(response?.status);
        }
      })
      .catch((error) => {
        console.error(
          'statusComponent - Error requesting bissnesses list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const handleOnUpdateList = (selectedStatus) => {
    getStatus();
  };

  const handleOnFilterStatus = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.title?.toString().toLowerCase();
        const field2 = item.color?.toString().toLowerCase();
        return field1.includes(filteringText) || field2.includes(filteringText);
      });
    }

    setStatus(filteredItems);
  };

  const handleOnAddStatus = () => {
    setSelectedStatus(Object.assign({}, statusModal));
    setModalMode('status');
  };

  const handleOnEditStatus = (row) => {
    console.log(row);
    setSelectedStatus(row);
    setModalMode('status');
  };

  const handleOnModalClose = () => {
    setSelectedStatus(null);
    setModalMode(null);
  };

  const handleOnDeleteStatus = (row) => {
    setSelectedStatus(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteStatusService({ status_id: selectedStatus.id })
      .then(() => {
        getStatus();
        toast.success('Responsable borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar al responsable.');
      })
      .finally(() => {
        setSelectedStatus(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <StatusView
        loading={loading}
        status={status}
        onFilterStatus={handleOnFilterStatus}
        onAddStatus={handleOnAddStatus}
        onEditStatus={handleOnEditStatus}
        onDeleteStatus={handleOnDeleteStatus}
      />
      {selectedStatus && modalMode === 'status' && (
        <StatusModal
          statusData={selectedStatus}
          totalItems={status.length}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedStatus && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Responsable "
        >
          {'¿Estas seguro que quieres borrar la Responsable  ' +
            selectedStatus.title +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default StatusComponent;
