import request from '../api-connector';

export const getServicesService = () => {
  const serviceDef = {
    url: 'service/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const AddServiceService = ({
  name,
  phone,
  client,
  code,
  email,
  staff_id,
  status_id,
  planned_delivery_date
}) => {
  const serviceDef = {
    url: 'service/create',
    method: 'post',
    params: {
      name,
      phone,
      client,
      code,
      email,
      staff_id,
      status_id,
      planned_delivery_date
    }
  };
  return request(serviceDef);
};

export const updateServiceService = ({
  id,
  name,
  phone,
  client,
  code,
  email,
  staff_id,
  status_id,
  planned_delivery_date
}) => {
  const serviceDef = {
    url: 'service/update',
    method: 'post',
    params: {
      id,
      name,
      phone,
      client,
      code,
      email,
      staff_id,
      status_id,
      planned_delivery_date
    }
  };
  return request(serviceDef);
};

export const deleteServiceService = ({ service_id }) => {
  const serviceDef = {
    url: 'service/delete',
    method: 'post',
    params: { service_id }
  };
  return request(serviceDef);
};

export const sendEmailNotificationClientService = ({ service_id }) => {
  const serviceDef = {
    url: 'service/client-notication',
    method: 'post',
    params: {
      service_id
    }
  };
  return request(serviceDef);
};
