/** Import react/libraries section **/
import React from "react";

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import "./ProfileModalStyles.scss";

/** Import component section **/
import DropFile from "../../../components/DropFile";

const profileModalView = ({
  profile,
  onClose,
  onSave,
  disabled,
  onFileUploaded,
}) => {
  return (
    <DropFile
      open={true}
      title="Editar logo"
      url={profile?.logo_url}
      onFileUploaded={onFileUploaded}
      onConfirm={onSave}
      onClose={onClose}
      confirmText="Enviar"
      cancelText="Cancelar"
      disabled={disabled}
      width={400}
    />
  );
};

export default profileModalView;
