/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addPermissionService,
  updatePermissionService
} from '../../../api/services/permission-services';

/** Import component section **/
import PermissionsModalView from './PermissionsModalView';

const PermissionModalComponent = ({
  permissionData,
  onClose,
  onUpdateList
}) => {
  const [permission, setPermission] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newPermission = Object.assign(permissionData);
    setPermission(newPermission);
  }, [permissionData]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setPermission((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!permission) return;
    let validated = true;
    const code = permission?.code?.toString().trim();
    const type = permission?.type?.toString().trim();
    if (code === '' || type === '') {
      validated = false;
    }
    setDataComplete(validated);
  }, [permission]);

  const handleOnSave = () => {
    if (permission.id) {
      updatePermission();
    } else {
      createPermission();
    }
  };

  const createPermission = () => {
    addPermissionService(permission)
      .then((response) => {
        toast.success('Permission created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Permission create error');
      });
  };

  const updatePermission = () => {
    updatePermissionService(permission)
      .then(() => {
        toast.success('Permission updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Permission update error');
      });
  };

  return (
    <PermissionsModalView
      permission={permission}
      onInputChange={onInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default PermissionModalComponent;
