/** Import react/libraries section **/
import React, { useEffect, useState } from "react";

/** Import helpers section **/
import {
  addBusinessService,
  updateBusinessService,
} from "../../../api/services/businesses-services";
import { toast } from "react-toastify";

import { isValidEmail } from "../../../utils/validations";

/** Import component section **/
import BusinessModalView from "./BusinessModalView";

const BusinessModalComponent = ({ businessesData, onClose, onUpdateList }) => {
  const [businesses, setBusiness] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    setBusiness(businessesData);
  }, [businessesData]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setBusiness((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setBusiness((prevState) => ({
      ...prevState,
      active: value,
    }));
  };

  useEffect(() => {
    if (!businesses) return;
    let validated = true;
    const comercialName = businesses?.comercial_name?.trim();
    const ownerName = businesses?.owner_name?.trim();
    const ownerEmail = businesses?.owner_email?.trim();
    const rfc = businesses?.rfc;
    console.log(isValidEmail(ownerEmail));
    if (
      comercialName === "" ||
      ownerEmail === "" ||
      ownerName === "" ||
      !rfc ||
      !isValidEmail(ownerEmail)
    ) {
      validated = false;
    }
    setDataComplete(validated);
  }, [businesses]);

  const handleOnSave = () => {
    if (businessesData.id) {
      updateBusiness();
    } else {
      createBusiness();
    }
  };

  const createBusiness = () => {
    addBusinessService(businesses)
      .then((response) => {
        toast.success("Bussinness created");
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.success("Bussinness create error");
      });
  };

  const updateBusiness = () => {
    updateBusinessService(businesses)
      .then(() => {
        toast.success("Bussinness updated");
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.success("Bussinness update error");
      });
  };

  return (
    <BusinessModalView
      businesses={businessesData}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default BusinessModalComponent;
