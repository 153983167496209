/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './StatusModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import ColorSelect from '../../../components/ColorSelect';
import MUISelect from '../../../components/inputs/MUISelect';

const StatusModalView = ({
  status,
  onInputChange,
  disabled,
  onClose,
  onSave,
  sequenceList
}) => {
  const title = status?.id ? 'Editar status' : 'Agregar nuevo status';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="statusmodal">
        <MUITextField
          label="Titulo del status"
          type="text"
          name="title"
          defaultValue={status?.title}
          onChange={onInputChange}
        />
        <div className="statusmodal_color">
          <div className="statusmodal_color_label">Color:</div>
          <ColorSelect
            name="color"
            value={status?.color}
            onChange={onInputChange}
          />
        </div>
        <MUISelect
          label="Posición en lista"
          defaultValue={
            status?.sequence ? status?.sequence : sequenceList.length
          }
          items={sequenceList}
          idSelector="sequence"
          selector="sequence"
          name="sequence"
          id="sequence"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default StatusModalView;
