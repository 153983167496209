import request from '../api-connector';

export const singupsService = ({
  email,
  first_name,
  last_name,
  password,
  password_confirmation,
  comercial_name,
  rfc
}) => {
  const serviceDef = {
    url: 'register',
    method: 'post',
    params: {
      email,
      first_name,
      last_name,
      password,
      password_confirmation,
      comercial_name,
      rfc
    }
  };
  return request(serviceDef);
};

export const emailValidationService = ({ token }) => {
  const serviceDef = {
    url: 'email/verify',
    method: 'post',
    params: { token }
  };
  return request(serviceDef);
};
