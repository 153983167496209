/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/

/** Import helpers section **/
import './ServiceItemHistoryStyles.scss';
import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const ServiceItemHistoryView = ({ loading, history, title, clientName }) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Fecha de modificación',
        selector: (row) => row?.date,
        sortable: true,
        center: true
      },
      {
        name: 'Partida',
        selector: (row) => row?.item_name,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus partida',
        selector: (row) => row?.status_item,
        sortable: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle
        title={
          'Historial de Partidas del servicio: ' +
          title +
          ' cliente: ' +
          clientName
        }
        backUrl={SERVICES_ROUTE}
      />
      <DataTable data={history} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ServiceItemHistoryView;
