import request from '../api-connector';

export const geTrackingService = ({ token }) => {
  const serviceDef = {
    url: 'tracking/list',
    method: 'get',
    params: { token }
  };
  return request(serviceDef);
};
