import request from '../api-connector';

export const getStaffsService = () => {
  const serviceDef = {
    url: 'staff/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getStaffsSelectService = () => {
  const serviceDef = {
    url: 'staff/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addStaffsService = ({ name, email }) => {
  const serviceDef = {
    url: 'staff/create',
    method: 'post',
    params: {
      name,
      email
    }
  };
  return request(serviceDef);
};

export const updateStaffsService = ({ id, name, email }) => {
  const serviceDef = {
    url: 'staff/update',
    method: 'post',
    params: {
      id,
      name,
      email
    }
  };
  return request(serviceDef);
};

export const deleteStaffsService = ({ staff_id }) => {
  const serviceDef = {
    url: 'staff/delete',
    method: 'post',
    params: {
      staff_id
    }
  };
  return request(serviceDef);
};

export const sendEmailNotificationStaffService = ({ service_id }) => {
  const serviceDef = {
    url: 'staff/service-notification',
    method: 'post',
    params: {
      service_id
    }
  };
  return request(serviceDef);
};
