/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

/** Import helpers section **/
import './ProfileStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import MUITextField from '../../components/inputs/MUITextField';

const ProfileView = ({
  profile,
  validPassword,
  onEditProfile,
  onUpdatePassword,
  onInputChange,
  onClose,
  onSave
}) => {
  return (
    <>
      <div className="profile">
        <PageTitle title="Perfil" />
        <Paper className="profile_logo">
          <div className="profile_title">Logotipo</div>
          <div className="profile_logo_container">
            <div className="profile_logo_container_area">
              {profile?.logo_url && (
                <img alt="profile_logo" src={profile?.logo_url} />
              )}
            </div>

            <Button onClick={onEditProfile}>Editar logotipo</Button>
          </div>
        </Paper>

        <Paper className="profile_passwordChange">
          <div className="profile_title">Contraseña</div>
          <div className="profile_passwordChange_form">
            <MUITextField
              label="Contraseña actual"
              id="current_password"
              name="current_password"
              type="password"
              defaultValue={profile?.current_password}
              onChange={onInputChange}
            />
            <MUITextField
              label="Nueva contraseña"
              id="new_password"
              name="new_password"
              type="password"
              defaultValue={profile?.new_password}
              onChange={onInputChange}
            />
            <MUITextField
              label="Confirmar contraseña"
              id="confirm_password"
              name="confirm_password"
              type="password"
              defaultValue={profile?.confirm_password}
              onChange={onInputChange}
            />
          </div>
          <Button onClick={onUpdatePassword} disabled={!validPassword}>
            Cambiar contraseña
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default ProfileView;
