import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { TwitterPicker } from 'react-color';
// https://casesandberg.github.io/react-color/

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  swatch: {
    padding: '5px',
    background: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    display: 'inline-block',
    cursor: 'pointer'
  },
  color: {
    width: '32px',
    height: '20px',
    borderRadius: '2px',
    border: '1px solid #ccc'
  },
  popover: {
    position: 'absolute',
    // width: '200px',
    zIndex: 2,
    left: '-280px',
    top: '-40px'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
}));

export const ColorSelect = ({ name, value, onChange }) => {
  const classes = useStyles();
  const [colorSelected, setColorSelected] = useState(value);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handlePickerClose = () => {
    setDisplayColorPicker(false);
  };

  const handleSwatchClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleOnChangeComplete = (color) => {
    setDisplayColorPicker(false);
    setColorSelected(color.hex);
    onChange({ name, value: color.hex });
  };

  return (
    <div className={classes.root}>
      <div className={classes.swatch} onClick={handleSwatchClick}>
        <div
          style={{ backgroundColor: colorSelected }}
          className={classes.color}
        />
      </div>
      {displayColorPicker && (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={handlePickerClose} />
          <TwitterPicker
            color={colorSelected}
            onChangeComplete={handleOnChangeComplete}
            triangle={'bottom-right'}
          />
        </div>
      )}
    </div>
  );
};

export default ColorSelect;
