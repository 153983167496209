import request from '../api-connector';

export const getProfileService = () => {
  const serviceDef = {
    url: 'profile/read',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const updateProfileService = ({ file }) => {
  const dataForm = new FormData();
  dataForm.append('file', file);
  const serviceDef = {
    url: 'profile/update',
    method: 'post',
    params: dataForm
  };
  return request(serviceDef);
};

export const changePasswordService = ({ current_password, new_password }) => {
  const serviceDef = {
    url: 'profile/change-password',
    method: 'post',
    params: { current_password, new_password }
  };
  return request(serviceDef);
};
