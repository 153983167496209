/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './ServiceModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import MUIDateField from '../../../components/inputs/MUIDateField';
import MUISelect from '../../../components/inputs/MUISelect';

const ServiceModalView = ({
  service,
  onInputChange,
  onClose,
  onSave,
  disabled,
  loading,
  staffs,
  status
}) => {
  const title = service?.id ? 'Editar servicio' : 'Agregar nuevo servicio';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={loading || disabled}
      confirmText={loading ? 'Espere...' : 'Ok'}
    >
      <div className="servicesmodal">
        <MUITextField
          label="Nombre de servicio"
          type="text"
          name="name"
          defaultValue={service?.name}
          onChange={onInputChange}
        />
        <MUITextField
          label="Código de servicio"
          type="text"
          name="code"
          defaultValue={service?.code}
          onChange={onInputChange}
        />
        <MUITextField
          label="Correo electrónico del cliente"
          type="text"
          name="email"
          defaultValue={service?.email}
          onChange={onInputChange}
        />
        <MUITextField
          label="Nombre del Cliente"
          type="text"
          name="client"
          defaultValue={service?.client}
          onChange={onInputChange}
        />
        <MUITextField
          label="WhatsApp"
          type="text"
          name="phone"
          defaultValue={service?.phone}
          onChange={onInputChange}
        />
        <MUIDateField
          id="planned_delivery_date"
          name="planned_delivery_date"
          label="Fecha sugerida de entrega"
          value={service?.planned_delivery_date}
          onChange={onInputChange}
        />
        <MUISelect
          label="Estatus"
          items={status}
          defaultValue={service?.status_id}
          idSelector="id"
          selector="title"
          name="status_id"
          id="status_id"
          onChange={(data) => onInputChange(data, 'status_id')}
        />

        <MUISelect
          label="Responsable"
          items={staffs}
          defaultValue={service?.staff_id}
          idSelector="id"
          selector="name"
          name="staff_id"
          id="staff_id"
          onChange={(data) => onInputChange(data, 'staff_id')}
        />
      </div>
    </ModalComponent>
  );
};

export default ServiceModalView;
