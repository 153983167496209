/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './StaffModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';

const SubscriptionModalView = ({
  staff,
  onInputChange,
  onClose,
  onSave,
  disabled
}) => {
  const title = staff?.id ? 'Editar responsable' : 'Agregar nuevo responsable';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="staffsmodal">
        <MUITextField
          label="Correo electrónico del responsable"
          type="text"
          name="email"
          defaultValue={staff?.email}
          onChange={onInputChange}
        />
        <MUITextField
          label="Nombre del responsable"
          type="text"
          name="name"
          defaultValue={staff?.name}
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default SubscriptionModalView;
