import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
// import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AssessmentIcon from '@mui/icons-material/Assessment';

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
// const USER_MENU = 'users';
const BUSINESSES_MENU = 'businesses';
const CONFIGURATION_MENU = 'configuration';
const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';
const SUBSCRIPTIONS_MENU = 'subscriptions';
const STAFFS_MENU = 'staffs';
const STATUS_MENU = 'status';
const PROFILES_MENU = 'profile';
const SERVICES_MENU = 'services';
const DASHBOARD_MENU = 'dashboard';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  // {
  //   id: USER_MENU,
  //   label: 'Usuarios',
  //   url: routes.USERS_ROUTE,
  //   icon: PersonIcon
  // },
  {
    id: DASHBOARD_MENU,
    label: 'Dashboard',
    url: routes.DASHBOARD_ROUTE,
    icon: AssessmentIcon
  },
  {
    id: SUBSCRIPTIONS_MENU,
    label: 'Subscripciones',
    url: routes.SUBSCRIPTIONS_ROUTE,
    icon: SubscriptionsIcon
  },
  {
    id: BUSINESSES_MENU,
    label: 'Negocios',
    url: routes.BUSSINESES_ROUTE,
    icon: WorkIcon
  },
  {
    id: STAFFS_MENU,
    label: 'Responsables',
    url: routes.STAFFS_ROUTE,
    icon: AccountBoxIcon
  },
  {
    id: STATUS_MENU,
    label: 'Estatus',
    url: routes.STATUS_ROUTE,
    icon: ToggleOnIcon
  },
  {
    id: PROFILES_MENU,
    label: 'Perfil',
    url: routes.PROFILES_ROUTE,
    icon: AssignmentIndIcon
  },
  {
    id: SERVICES_MENU,
    label: 'Servicios',
    url: routes.SERVICES_ROUTE,
    icon: HomeRepairServiceIcon
  },
  {
    id: CONFIGURATION_MENU,
    label: 'Configuración',
    icon: SettingsIcon,
    items: [
      {
        id: PERMISSIONS_MENU,
        label: 'Permisos por rol',
        url: routes.ROLE_PERMISSIONS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: PERMISSIONS_CATALOGUE_MENU,
        label: 'Permisos',
        url: routes.PERMISSIONS_ROUTE,
        icon: LockIcon
      }
    ]
  }
];
export default MENU_ITEMS;
