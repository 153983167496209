import { createTheme } from '@mui/material/styles';
import * as colors from './colors';

const theme = createTheme({
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '>:not(:first-of-type)': {
            marginLeft: '15px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
          width: 'max-content',
          minWidth: '100px',
          color: 'white',
          fontSize: '10px',
          textTransform: 'uppercase',
          borderRadius: '6px',
          backgroundColor: colors.color_primary,
          '&:hover': {
            backgroundColor: colors.color_primary
          },
          '&:disabled': {
            color: colors.color_cancel
          }
        },
        text: {
          padding: '0 15px'
        }
      },
      variants: [
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: colors.color_cancel,
            color: 'white',
            '&:hover': {
              backgroundColor: colors.color_cancel
            }
          }
        }
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    }
  },
  status: {
    danger: '#ff0000'
  },
  palette: {
    primary: {
      main: colors.color_primary
    },
    secondary: {
      main: colors.color_secondary
    },
    light: colors.color_light,
    complementary: {
      main: colors.color_complementary
    },
    complementary2: {
      main: colors.color_complementary_2
    },
    complementary3: {
      main: colors.color_complementary_3
    },
    drawerBackground: {
      main: colors.color_secondary
    },
    success: {
      light: colors.color_check_light,
      main: colors.color_check
    },
    error: {
      main: colors.color_delete
    },
    dark: colors.color_dark,
    normal: colors.color_normal
  },
  typography: {
    fontFamily: ['OpenSans-Regular'].join(','),
    fontSize: 11,
    // fontWeight: 200,
    body2: {
      // fontWeight: 600,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bolder'
    },
    h4: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: colors.color_normal
    },
    h5: {
      fontSize: 16
    }
  }
});

export default theme;
