/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

/** Import helpers section **/
import './BusinessesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import MUISelect from '../../components/inputs/MUISelect';

const BusinessesView = ({
  loading,
  businesses,
  onFilterBusinesses,
  onAddBusiness,
  onEditBusiness,
  onDeleteBusiness,
  onActiveBusiness,
  activeOptions,
  selectedActiveId,
  onSelectedActiveChange
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active businesses_active"
          onClick={() => onActiveBusiness(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon businesses_active"
        onClick={() => onActiveBusiness(row)}
      />
    );
  };

  const RegisteredUser = ({ row }) => {
    if (row.user_id)
      return (
        <ToggleOnIcon
          className="g-activeicon active businesses_active"
          fontSize="large"
        />
      );
    return <ToggleOffIcon color="disabled" fontSize="large" />;
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditBusiness(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteBusiness(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre comercial',
        selector: (row) => row?.comercial_name,
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: (row) => row?.rfc,
        sortable: true,
        center: true
      },
      {
        name: 'Representante',
        selector: (row) => row?.owner_name,
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Email de administrador',
        selector: (row) => row?.owner_email,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus',
        selector: (row) => row?.active,
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'Registrado',
        selector: (row) => (row?.user_id ? 'Si' : 'No'),
        cell: (row) => <RegisteredUser row={row} />,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Negocios" />
      <Paper className="g-page-header businesses_header">
        <div className="businesses_header_filters">
          <MUISelect
            label="Negocios activos / Negocios no activos"
            defaultValue={selectedActiveId}
            items={activeOptions}
            idSelector="id"
            selector="active"
            name="active"
            id="active"
            onChange={onSelectedActiveChange}
          />
          <FilterBy onChange={onFilterBusinesses} />
        </div>
        <Button onClick={onAddBusiness} startIcon={<AddCircleIcon />}>
          Agregar nuevo negocio
        </Button>
      </Paper>

      <DataTable
        data={businesses}
        columns={tableColumns}
        loading={loading}
        showDownload
      />
    </>
  );
};

export default BusinessesView;
