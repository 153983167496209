/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import helpers section **/
import './StatusStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const BusinessesView = ({
  loading,
  status,
  onFilterStatus,
  onAddStatus,
  onEditStatus,
  onDeleteStatus
}) => {
  const ColorCell = ({ row }) => {
    const color = row?.color ? row.color : '#ffff';
    return (
      <div className="status_color-cell">
        <div
          style={{ backgroundColor: color }}
          className="status_color-cell_color"
        />
      </div>
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditStatus(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteStatus(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Título',
        selector: (row) => row?.title,
        sortable: true,
        center: true
      },
      {
        name: 'Color',
        cell: (row) => <ColorCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: 'Secuencia',
        cell: (row) => row?.sequence,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Estatus" />
      <Paper className="g-page-header status_header">
        <div className="status_header_filterby">
          <FilterBy onChange={onFilterStatus} />
        </div>
        <Button onClick={onAddStatus} startIcon={<AddCircleIcon />}>
          Agregar nuevo estatus
        </Button>
      </Paper>

      <DataTable data={status} columns={tableColumns} loading={loading} />
    </>
  );
};

export default BusinessesView;
