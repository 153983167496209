/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import helpers section **/
import './StaffsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const BusinessesView = ({
  loading,
  staffs,
  onFilterStaff,
  onAddStaff,
  onEditStaff,
  onDeleteStaff
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditStaff(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteStaff(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: (row) => row?.email,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Responsables" />
      <Paper className="g-page-header staffs_header">
        <div className="staffs_header_filterby">
          <FilterBy onChange={onFilterStaff} />
        </div>
        <Button onClick={onAddStaff} startIcon={<AddCircleIcon />}>
          Agregar nuevo responsable
        </Button>
      </Paper>

      <DataTable data={staffs} columns={tableColumns} loading={loading} />
    </>
  );
};

export default BusinessesView;
