/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';

/** Import helpers section **/
import { geTrackingService } from '../../api/services/tracking-services';

/** Import resources section **/

import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import TrackingView from './TrackingView';

const TrackingComponent = (serviceToken) => {
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  let pageParams = useParams();
  let token = pageParams.token || serviceToken?.serviceToken;

  if (!token) history.push(SERVICES_ROUTE);

  const getTracking = useCallback(() => {
    setLoading(true);
    geTrackingService({ token: token })
      .then((response) => {
        setTracking(response?.tracking);
      })
      .catch((error) => {
        console.error('trackingComponent - Error requesting list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    getTracking();
  }, [getTracking]);

  return (
    <>
      <TrackingView loading={loading} tracking={tracking} />
    </>
  );
};

export default TrackingComponent;
