/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

/** Import helpers section **/
import './PermissionsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const PermissionsView = ({
  loading,
  permissions,
  onAddPermission,
  onEditPermission,
  onDeletePermission
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditPermission(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeletePermission(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Código',
        selector: (row) => row?.code,
        sortable: true,
        center: true
      },
      {
        name: 'Tipo',
        selector: (row) => row?.type_name,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="permissions">
        <PageTitle title="Permisos" />
        <Paper className="g-page-header permissions_header">
          <Button
            className="permissions_header_button"
            onClick={onAddPermission}
            startIcon={<AddCircleIcon />}
          >
            Agregar nuevo permiso
          </Button>
        </Paper>

        <DataTable
          data={permissions}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </>
  );
};

export default PermissionsView;
