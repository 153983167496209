/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import './DashboardStyles.scss';

/** Import resources section **/
import Paper from '@mui/material/Paper';

/** Import component section **/
import LoadingComponent from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
const DashboardView = ({ loading, dashboardData }) => {
  return (
    <>
      <PageTitle title={'Dashboard'} />
      {loading && <LoadingComponent />}
      {!loading && dashboardData.length > 0 && (
        <Paper className="dashboard">
          {dashboardData?.map((item, index) => (
            <div className="dashboard_item" key={'dash' + index}>
              <div
                className="dashboard_item_value"
                style={{ borderColor: item.color }}
              >
                {item.total || 0}
              </div>
              <div className="dashboard_item_title">{item.title}</div>
            </div>
          ))}
        </Paper>
      )}
    </>
  );
};

export default DashboardView;
