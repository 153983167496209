/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

/** Import helpers section **/
import { getServiceItemHistoryService } from '../../api/services/history-services';

/** Import resources section **/

import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import HistoryView from './ServiceItemHistoryView';

const ServiceItemHistoryComponent = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const historyService = useHistory();
  let serviceId = useLocation().state?.id;
  let title = useLocation().state?.title;
  let clientName = useLocation().state?.client;

  if (!serviceId) historyService.push(SERVICES_ROUTE);

  const getHistory = useCallback(() => {
    setLoading(true);
    getServiceItemHistoryService({ service_item_id: serviceId })
      .then((response) => {
        setHistory(response?.history);
      })
      .catch((error) => {
        console.error('historyComponent - Error requesting list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [serviceId]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return (
    <>
      <HistoryView
        loading={loading}
        title={title}
        clientName={clientName}
        history={history}
      />
    </>
  );
};

export default ServiceItemHistoryComponent;
