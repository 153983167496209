/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addSubscriptionService,
  updateSubscriptionService
} from '../../../api/services/subscriptions-services';

/** Import component section **/
import SubscriptionModalView from './SubscriptionModalView';
import { getBusinessSelectService } from '../../../api/services/businesses-services';

const SubscriptionModalComponent = ({
  subscriptionData,
  onClose,
  onUpdateList
}) => {
  const [subscription, setSubscription] = useState(null);
  const [business, setBusinesses] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const getBusinesses = useCallback(() => {
    getBusinessSelectService()
      .then((response) => {
        if (response.business) {
          setBusinesses(response?.business);
        }
      })
      .catch((error) => {
        console.error(
          'businessesComponent - Error requesting business list: ',
          error
        );
      });
  }, []);

  useEffect(() => {
    setSubscription(subscriptionData);
    getBusinesses();
  }, [subscriptionData, getBusinesses]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setSubscription((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setSubscription((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!subscription) return;
    let validated = true;
    const endDate = subscription?.end_date?.trim();
    const startDate = subscription?.start_date?.trim();
    const businessId = subscription?.business_id;
    if (!endDate || !startDate || !businessId) {
      validated = false;
    }
    setDataComplete(validated);
  }, [subscription]);

  const handleOnSave = () => {
    if (subscriptionData.id) {
      updateBusiness();
    } else {
      createBusiness();
    }
  };

  const createBusiness = () => {
    addSubscriptionService(subscription)
      .then((response) => {
        toast.success('Bussinness created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Bussinness create error');
      });
  };

  const updateBusiness = () => {
    updateSubscriptionService(subscription)
      .then(() => {
        toast.success('Bussinness updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Bussinness update error');
      });
  };

  return (
    <SubscriptionModalView
      subscription={subscriptionData}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
      business={business}
    />
  );
};

export default SubscriptionModalComponent;
