/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/** Import resources section **/
import { setPermissions } from './store/slices/permissions-slice';
import {
  getTokenStored,
  setSession,
  clearSession
} from './store/slices/session-slice';

/** Import styles section **/
import './styles/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify.scss';

/** Import helpers section **/
import { ToastContainer } from 'react-toastify';

import { authService } from './api/services/session-services';

/** Import component section **/
import MainComponent from './layout/Main';
import SplashComponent from './layout/Splash/SplashComponent';

function App() {
  const [authenticateFinished, setAuthenticateFinished] = useState(false);
  let dispatch = useDispatch();

  const auth = useCallback(
    (token) => {
      authService()
        .then((response) => {
          let permissions = response?.permissions;
          const actions = permissions?.actions;
          const routes = permissions?.routes;
          const menus = permissions?.menus;

          dispatch(setPermissions({ actions, routes, menus }));
          let userInfo = {
            token: response?.token,
            role: response?.role,
            profile: {
              name: response?.first_name + ' ' + response?.last_name,
              business_name: response?.business_name,
              logo_url: response?.logo_url
            }
          };
          dispatch(setSession(userInfo));
        })
        .catch((err) => {})
        .finally(() => {
          setAuthenticateFinished(true);
        });
    },
    [dispatch]
  );

  // We use this effect for regenerate session if exists, just before mount MainContainer.
  useEffect(() => {
    getTokenStored
      .then((tokenStored) => {
        if (tokenStored) {
          auth(tokenStored);
        } else {
          setAuthenticateFinished(true);
        }
      })
      .catch((err) => {
        setAuthenticateFinished(true);
      });
  }, [dispatch, auth]);

  // Catch the even unauthorized send by api connector
  document.addEventListener('unauthorized', function () {
    dispatch(clearSession());
  });

  return (
    <>
      {authenticateFinished ? <MainComponent /> : <SplashComponent />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        toastClassName="g-toast"
      />
    </>
  );
}

export default App;
