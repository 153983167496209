/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import services section **/
import {
  getSubscriptionsService,
  deleteSubscriptionService,
  updateActiveSubscriptionService,
  sendEmailSubscriptionService
} from '../../api/services/subscriptions-services';
import { getBusinessSelectService } from '../../api/services/businesses-services';

/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import SubscriptionModal from './SubscriptionModal';
import SubscriptionsView from './SubscriptionsView';

const SubscriptionsModel = {
  id: null,
  business_id: null,
  start_date: '',
  end_date: '',
  reference: '',
  active: ''
};

const SubscriptionsComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [subscriptions, setSubscriptions] = useState();
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [filterByText, setFilterByText] = useState('');
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const getBusiness = useCallback(() => {
    setLoading(true);
    getBusinessSelectService()
      .then((response) => {
        const businessArr = response?.business;
        if (businessArr) {
          setBusinesses(businessArr);
        }
      })
      .catch((error) => {
        console.error('Error requesting business list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSubscriptions = useCallback((businessId) => {
    if (!businessId) {
      setSubscriptions([]);
      setOriginalData([]);
      return;
    }
    let params = {
      business_id: businessId
    };
    setLoading(true);
    getSubscriptionsService(params)
      .then((response) => {
        const subscriptionArr = response?.subscriptions;
        if (subscriptionArr) {
          setSubscriptions(subscriptionArr);
          setOriginalData(subscriptionArr);
        }
      })
      .catch((error) => {
        console.error(
          'subscriptionsComponent - Error requesting subscriptions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnUpdateList = () => {
    getSubscriptions(selectedBusinessId);
  };

  useEffect(() => {
    getBusiness();
  }, [getBusiness]);

  const handleonAddSubscription = () => {
    setSelectedSubscription(Object.assign({}, SubscriptionsModel));
    setModalMode('subscriptions');
  };

  const handleonEditSubscription = (row) => {
    let item = Object.assign({}, row);
    setSelectedSubscription(item);
    setModalMode('subscriptions');
  };

  const handleOnModalClose = () => {
    setSelectedSubscription(null);
    setModalMode(null);
  };

  const handleonDeleteSubscription = (row) => {
    setSelectedSubscription(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteSubscriptionService({ subscription_id: selectedSubscription.id })
      .then(() => {
        getSubscriptions(selectedBusinessId);
        toast.success('Subscripción borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar la subscripción.');
      })
      .finally(() => {
        setSelectedSubscription(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedSubscription(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    var active = selectedSubscription?.active === 0 ? 1 : 0;
    updateActiveSubscriptionService({
      subscription_id: selectedSubscription.id,
      active: active
    })
      .then(() => {
        getSubscriptions(selectedSubscription.business_id);
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedSubscription(null);
        setModalMode(null);
      });
  };

  const filterList = useCallback(() => {
    let filteredItems = [...originalData];

    // filtro texto
    if (!!filterByText && filterByText.length > 0) {
      const filteringText = filterByText?.toLowerCase();
      filteredItems = filteredItems.filter((item) => {
        const inField1 = item.owner_name
          ?.toString()
          .toLowerCase()
          ?.includes(filteringText);
        return inField1;
      });
    }

    // filtro fecha
    if (!!selectedDate) {
      let filteringDate = new Date(selectedDate + ' 00:00');
      filteredItems = filteredItems.filter((item) => {
        let startDate = new Date(item.start_date);
        let endDate = new Date(item.end_date);
        let inBetween = filteringDate >= startDate && filteringDate <= endDate;
        return inBetween;
      });
    }

    setSubscriptions(filteredItems);
  }, [originalData, filterByText, selectedDate]);

  useEffect(() => {
    filterList();
  }, [filterByText, selectedDate, filterList]);

  const handleOnFilterBy = (filterText) => {
    console.log(filterText);
    setFilterByText(filterText.trim());
  };

  const handleOnBusinessChange = (business) => {
    const businessId = business?.value;
    setSelectedBusinessId(businessId);
    getSubscriptions(businessId);
  };

  const handleOnSelectedDateChange = (dateData) => {
    const date = dateData?.value;
    setSelectedDate(date);
  };

  const handleOnSendEmail = (row) => {
    sendEmailSubscriptionService({ subscription_id: row.id })
      .then(() => {
        getSubscriptions(row.business_id);
        toast.success('Correo electrónico reenviado.');
      })
      .catch((error) => {
        toast.error('Error, no se pudo enviar el correo electrónico.');
      });
  };

  return (
    <>
      <SubscriptionsView
        loading={loading}
        subscriptions={subscriptions}
        onFilterBy={handleOnFilterBy}
        onAddSubscription={handleonAddSubscription}
        onEditSubscription={handleonEditSubscription}
        onDeleteSubscription={handleonDeleteSubscription}
        onActiveSubscription={handleOnActiveChange}
        businesses={businesses}
        selectedBusinessId={selectedBusinessId}
        onBusinessChange={handleOnBusinessChange}
        selectedDate={selectedDate}
        onSelectedDateChange={handleOnSelectedDateChange}
        onSendEmail={handleOnSendEmail}
      />
      {selectedSubscription && modalMode === 'subscriptions' && (
        <SubscriptionModal
          subscriptionData={selectedSubscription}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedSubscription && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Subscripción "
        >
          {'¿Estas seguro que quieres borrar la subscripción  ' +
            selectedSubscription.reference +
            '?'}
        </Modal>
      )}

      {selectedSubscription && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedSubscription?.active === 0
              ? 'Activar subscripción?'
              : 'Desactivar subscripción'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedSubscription?.active === 0 ? 'Activar' : 'Desactivar') +
            ' la subscripción ' +
            selectedSubscription.reference +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default SubscriptionsComponent;
