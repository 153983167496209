import request from '../api-connector';

export const getStatusService = () => {
  const serviceDef = {
    url: 'status/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getStatusSelectService = () => {
  const serviceDef = {
    url: 'status/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addStatusService = ({ status_id, title, color, sequence }) => {
  const serviceDef = {
    url: 'status/create',
    method: 'post',
    params: {
      status_id,
      title,
      color,
      sequence
    }
  };
  return request(serviceDef);
};

export const updateStatusService = ({ id, title, color, sequence }) => {
  const serviceDef = {
    url: 'status/update',
    method: 'post',
    params: {
      id,
      title,
      color,
      sequence
    }
  };
  return request(serviceDef);
};

export const deleteStatusService = ({ status_id }) => {
  const serviceDef = {
    url: 'status/delete',
    method: 'post',
    params: {
      status_id
    }
  };
  return request(serviceDef);
};

export const getStatusSequenceSelectService = () => {
  const serviceDef = {
    url: 'status/sequence/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
