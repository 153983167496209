import request from '../api-connector';

export const getDashboardService = () => {
  const serviceDef = {
    url: 'dashboard/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
