/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getStaffsService,
  deleteStaffsService
} from '../../api/services/staffs-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import StaffModal from './StaffModal';
import StaffsView from './StaffsView';

const StaffsModel = {
  id: null,
  name: '',
  email: '',
  businesses_id: ''
};

const StaffsComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStaffs = useCallback(() => {
    setLoading(true);
    getStaffsService()
      .then((response) => {
        setStaffs(response?.staffs);
        setOriginalData(response?.staffs);
      })
      .catch((error) => {
        console.error(
          'staffsComponent - Error requesting bissnesses list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getStaffs();
  }, [getStaffs]);

  const handleOnUpdateList = (selectedStaff) => {
    getStaffs();
  };

  const handleOnFilterStaff = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.reference?.toString().toLowerCase();
        return field1.includes(filteringText);
      });
    }

    setStaffs(filteredItems);
  };

  const handleOnAddStaff = () => {
    setSelectedStaff(Object.assign({}, StaffsModel));
    setModalMode('staffs');
  };

  const handleOnEditStaff = (row) => {
    setSelectedStaff(row);
    setModalMode('staffs');
  };

  const handleOnModalClose = () => {
    setSelectedStaff(null);
    setModalMode(null);
  };

  const handleOnDeleteStaff = (row) => {
    setSelectedStaff(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteStaffsService({ staff_id: selectedStaff.id })
      .then(() => {
        getStaffs();
        toast.success('Responsable borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar al responsable.');
      })
      .finally(() => {
        setSelectedStaff(null);
        setModalMode(null);
      });
  };

  return (
    <>
      <StaffsView
        loading={loading}
        staffs={staffs}
        onFilterStaff={handleOnFilterStaff}
        onAddStaff={handleOnAddStaff}
        onEditStaff={handleOnEditStaff}
        onDeleteStaff={handleOnDeleteStaff}
      />
      {selectedStaff && modalMode === 'staffs' && (
        <StaffModal
          staffData={selectedStaff}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedStaff && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Responsable "
        >
          {'¿Estas seguro que quieres borrar la Responsable  ' +
            selectedStaff.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default StaffsComponent;
