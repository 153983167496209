import request from '../api-connector';
export const getPermissionsService = () => {
  const serviceDef = {
    url: 'permission/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const deletePermissionService = ({ id }) => {
  const serviceDef = {
    url: 'permission/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getPermissionByIdService = ({ id }) => {
  const serviceDef = {
    url: 'permission/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addPermissionService = ({ code, type }) => {
  const serviceDef = {
    url: 'permission/create',
    method: 'post',
    params: { code, type }
  };
  return request(serviceDef);
};

export const updatePermissionService = ({ id, code, type }) => {
  const serviceDef = {
    url: 'permission/update',
    method: 'post',
    params: { id, code, type }
  };
  return request(serviceDef);
};

export const getPermissionsByRoleService = ({ id }) => {
  const serviceDef = {
    url: 'role-permission/list',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const checkRolePermissionsService = ({ role_id, permissions }) => {
  const serviceDef = {
    url: 'role-permission/create',
    method: 'post',
    params: { role_id, permissions }
  };
  return request(serviceDef);
};

export const getRolesService = () => {
  const serviceDef = {
    url: 'role/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
