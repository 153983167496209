/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import { updateProfileService } from '../../../api/services/profile-services';

/** Import component section **/
import ProfileModalView from './ProfileModalView';

const ProfileModalComponent = ({ profileData, onClose, onUpdate }) => {
  const [profile, setProfile] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    const newProfile = Object.assign(profileData);
    setProfile(newProfile);
  }, [profileData]);

  useEffect(() => {
    let validated = isFileLoaded ? true : false;
    setDataComplete(validated);
  }, [isFileLoaded]);

  const handleOnFileUploaded = (files = []) => {
    setIsFileLoaded(files.length > 0 ? true : false);
  };

  const updateProfile = (files) => {
    const payload = {
      file: files[0]
    };
    updateProfileService(payload)
      .then(() => {
        toast.success('Logo actualizado. Favor de recargar página.');
        onUpdate();
        onClose();
      })
      .catch((error) => {
        toast.error('Error al actualizar logotipo');
      });
  };

  const handleOnSave = (files) => {
    updateProfile(files);
  };

  return (
    <ProfileModalView
      profile={profile}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
      onFileUploaded={handleOnFileUploaded}
    />
  );
};

export default ProfileModalComponent;
