/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

/** Import helpers section **/
import './SubscriptionsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import MUISelect from '../../components/inputs/MUISelect';
import MUIDateField from '../../components/inputs/MUIDateField';
import DataTable from '../../components/DataTable';
const BusinessesView = ({
  loading,
  subscriptions,
  onFilterBy,
  onAddSubscription,
  onEditSubscription,
  onDeleteSubscription,
  onActiveSubscription,
  selectedBusinessId,
  businesses,
  onBusinessChange,
  selectedDate,
  onSelectedDateChange,
  onSendEmail
}) => {
  const ActiveCell = ({ row }) => {
    if (row.active)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active subscriptions_active"
          onClick={() => onActiveSubscription(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon subscriptions_active"
        onClick={() => onActiveSubscription(row)}
      />
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <ForwardToInboxIcon
          className="g-row-action-button complementary"
          onClick={() => onSendEmail(row)}
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditSubscription(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteSubscription(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Negocio',
        selector: (row) => row?.business_name,
        sortable: true,
        center: true
      },
      {
        name: 'Representante',
        selector: (row) => row?.owner_name,
        sortable: true,
        center: true
      },
      {
        name: 'Email del responsable',
        selector: (row) => row?.owner_email,
        sortable: true,
        center: true
      },
      {
        name: 'Fecha de inicio',
        selector: (row) => row?.start_date,
        sortable: true,
        center: true
      },
      {
        name: 'Fecha fin',
        selector: (row) => row?.end_date,
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Referencia',
        selector: (row) => row?.reference,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus',
        selector: (row) => row?.active,
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Subscripciones" />
      <Paper className="g-page-header subscriptions_header">
        <div className="subscriptions_header_filters">
          <MUISelect
            label="Negocio"
            defaultValue={selectedBusinessId}
            items={businesses}
            idSelector="id"
            selector="comercial_name"
            name="business"
            id="business"
            onChange={onBusinessChange}
          />
          <MUIDateField
            label="Fecha"
            value={selectedDate}
            name="end_date"
            onChange={onSelectedDateChange}
          />
          <FilterBy onChange={onFilterBy} />
        </div>
        <Button
          onClick={onAddSubscription}
          disabled={!selectedBusinessId}
          startIcon={<AddCircleIcon />}
        >
          Agregar nueva subscripción
        </Button>
      </Paper>

      <DataTable
        data={subscriptions}
        columns={tableColumns}
        loading={loading}
        showDownload
      />
    </>
  );
};

export default BusinessesView;
