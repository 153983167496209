/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

/** Import syles section **/
import './UserModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const UserModalView = ({
  user,
  modalMode,
  roles,
  onInputChange,
  onSwitchChange,
  onClose,
  onSave,
  disabled
}) => {
  const title =
    modalMode === 'password'
      ? 'Cambiar contraseña'
      : user?.id
      ? 'Editar usuario'
      : 'Agregar usuario';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="usermodal">
        {modalMode === 'user' && (
          <>
            <FormControlLabel
              className="usermodal_active"
              control={
                <Switch
                  label="Activo"
                  checked={user?.active === 1}
                  onChange={(event) => onSwitchChange(event)}
                  name="active"
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                />
              }
              label="Activo"
            />
            <MUITextField
              label="Nombre"
              defaultValue={user?.first_name}
              type="text"
              name="first_name"
              onChange={onInputChange}
            />
            <MUITextField
              label="Apellido"
              defaultValue={user?.last_name}
              type="text"
              name="last_name"
              onChange={onInputChange}
            />
            <MUITextField
              label="Email"
              defaultValue={user?.email}
              type="text"
              name="email"
              onChange={onInputChange}
            />
            <MUISelect
              label="Rol"
              defaultValue={user?.role_id}
              items={roles}
              idSelector="id"
              selector="title"
              noSelectedLabel="Select"
              name="role_id"
              id="role_id"
              onChange={onInputChange}
            />
          </>
        )}

        {(modalMode === 'password' || (modalMode === 'user' && !user?.id)) && (
          <>
            <MUITextField
              label="Contraseña"
              defaultValue={user?.password}
              type="password"
              name="password"
              error={user?.password !== user?.password_conf}
              onChange={(data) => onInputChange(data, 'password')}
            />

            <MUITextField
              label="Confirmar contraseña"
              defaultValue={user?.password_conf}
              type="password"
              name="password_conf"
              error={user?.password !== user?.password_conf}
              onChange={(data) => onInputChange(data, 'password_conf')}
            />
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default UserModalView;
