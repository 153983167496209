/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import './TrackingStyles.scss';

/** Import component section **/
import StatusColor from '../../components/StatusColor';

const TrackingView = ({ tracking }) => {
  const items = tracking?.items || [];
  return (
    <div className="tracking">
      <div className="tracking_header">
        <div className="tracking_header_info">
          <div className="tracking_header_info_label">Servicio:</div>
          {tracking?.name}
          <div className="tracking_header_info_space" />
          <div className="tracking_header_info_label">Cliente: </div>
          {tracking?.client}
        </div>

        <div className="tracking_header_status">
          <div className="tracking_header_status_label">
            {tracking?.service_status_title}{' '}
          </div>
          <StatusColor color={tracking?.color} />
        </div>
      </div>
      <div className="tracking_items">
        <div className="tracking_items_item header">
          <div className="tracking_items_item_name">Partida</div>
          <div className="tracking_items_item_status">Estatus</div>
          <div className="tracking_items_item_description">Descripcion</div>
        </div>
        {items.map((item, index) => (
          <div className="tracking_items_item" key={'item' + index}>
            <div className="tracking_items_item_name">{item?.name}</div>
            <div className="tracking_items_item_status">
              {item?.item_status} <StatusColor color={item?.color} />
            </div>
            <div className="tracking_items_item_description">
              {item?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrackingView;
