/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getPermissionsByRoleService,
  checkRolePermissionsService,
  getRolesService
} from '../../api/services/permission-services';

/** Import resources section **/

/** Import component section **/
import RolePermissionsView from './RolePermissionsView';

const PermissionsComponent = () => {
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissionss] = useState([]);

  const getPermissions = (id) => {
    setLoading(true);
    getPermissionsByRoleService({ id })
      .then((response) => {
        setPermissions(response?.permissions);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getRoles = useCallback(() => {
    getRolesService()
      .then((response) => {
        if (response?.roles) setRoles(response?.roles);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleOnRoleChange = (data) => {
    const roleId = data.value;
    setSelectedRole(roleId);
    getPermissions(roleId);
  };

  const handleOnSelectedChange = (state) => {
    // { allSelected, selectedCount, selectedRows }
    setSelectedPermissionss(state.selectedRows);
  };
  const handleOnSave = () => {
    // { allSelected, selectedCount, selectedRows }
    const params = {
      role_id: selectedRole,
      permissions: selectedPermissions
    };
    setLoading(true);
    checkRolePermissionsService(params)
      .then((response) => {
        toast.success('Permisos actualizados');
        getPermissions(selectedRole);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
    //terminando api getPermissions()
  };
  return (
    <>
      <RolePermissionsView
        loading={loading}
        permissions={permissions}
        roles={roles}
        selectedRole={selectedRole}
        onRoleChange={handleOnRoleChange}
        onSelectedChange={handleOnSelectedChange}
        onSave={handleOnSave}
      />
    </>
  );
};

export default PermissionsComponent;
