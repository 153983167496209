/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/

/** Import helpers section **/
import './ServiceHistoryStyles.scss';
import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const ServiceHistoryView = ({ loading, history, title, clientName }) => {
  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Fecha de modificación',
        selector: (row) => row?.date,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus de servicio modificado',
        selector: (row) => row?.status_service,
        sortable: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle
        title={
          'Historial de Partidas del servicio: ' +
          title +
          ' cliente: ' +
          clientName
        }
        backUrl={SERVICES_ROUTE}
      />
      <DataTable data={history} columns={tableColumns} loading={loading} />
    </>
  );
};

export default ServiceHistoryView;
