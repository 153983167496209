import request from '../api-connector';

export const getServiceItemsService = ({ service_id }) => {
  const serviceDef = {
    url: 'service-item/list',
    method: 'get',
    params: { service_id }
  };
  return request(serviceDef);
};

export const AddServiceItemService = ({
  name,
  description,
  quantity,
  service_id,
  status_id,
  planned_delivery_date
}) => {
  const serviceDef = {
    url: 'service-item/create',
    method: 'post',
    params: {
      name,
      description,
      quantity,
      service_id,
      status_id,
      planned_delivery_date
    }
  };
  return request(serviceDef);
};

export const updateServiceItemService = ({
  service_item_id,
  name,
  description,
  quantity,
  service_id,
  status_id,
  planned_delivery_date
}) => {
  const serviceDef = {
    url: 'service-item/update',
    method: 'post',
    params: {
      service_item_id,
      name,
      description,
      quantity,
      service_id,
      status_id,
      planned_delivery_date
    }
  };
  return request(serviceDef);
};

export const deleteServiceItemService = ({ service_item_id }) => {
  const serviceDef = {
    url: 'service-item/delete',
    method: 'post',
    params: { service_item_id }
  };
  return request(serviceDef);
};

export const sendEmailNotificationClientService = (params) => {
  const serviceDef = {
    url: 'service-item/client-notication',
    method: 'post',
    params: params
  };
  return request(serviceDef);
};
