/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './ItemModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';
import MUIDateField from '../../../components/inputs/MUIDateField';

const SubscriptionModalView = ({
  item,
  status,
  onInputChange,
  onClose,
  onSave,
  disabled,
  loading
}) => {
  const title = item?.id ? 'Editar partida' : 'Agregar partida';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={loading || disabled}
      confirmText={loading ? 'Espere...' : 'Ok'}
    >
      <div className="item_modal">
        <MUITextField
          label="Partida"
          type="text"
          name="name"
          id="name"
          defaultValue={item?.name}
          onChange={onInputChange}
        />
        <MUITextField
          label="Descripción"
          type="text"
          name="description"
          id="description"
          defaultValue={item?.description}
          onChange={onInputChange}
        />

        <MUIDateField
          id="planned_delivery_date"
          name="planned_delivery_date"
          label="Fecha sugerida de entrega"
          value={item?.planned_delivery_date}
          onChange={onInputChange}
        />
        <MUISelect
          label="Estatus"
          items={status}
          defaultValue={item?.status_id}
          idSelector="id"
          selector="title"
          name="status_id"
          id="status_id"
          onChange={(data) => onInputChange(data, 'status_id')}
        />
      </div>
    </ModalComponent>
  );
};

export default SubscriptionModalView;
