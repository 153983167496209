/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

/** Import syles section **/
import './BusinessModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal/ModalComponent';
import MUITextField from '../../../components/inputs/MUITextField';

const BusinessModalView = ({
  businesses,
  onInputChange,
  onSwitchChange,
  onClose,
  onSave,
  disabled
}) => {
  const title = businesses?.id ? 'Editar negocio' : 'Agregar nuevo negocio';
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={onSave}
      disabled={disabled}
    >
      <div className="businessmodal">
        <FormControlLabel
          className="businessmodal_active"
          control={
            <Switch
              label="Activo"
              checked={businesses?.active === 1}
              onChange={(event) => onSwitchChange(event)}
              name="active"
              color="primary"
              inputProps={{
                'aria-label': 'secondary checkbox'
              }}
            />
          }
          label="Activo"
        />
        <MUITextField
          label="Nombre comercial"
          defaultValue={businesses?.comercial_name}
          type="text"
          name="comercial_name"
          onChange={onInputChange}
        />
        <MUITextField
          label="Correo del administrador"
          defaultValue={businesses?.owner_email}
          type="text"
          name="owner_email"
          onChange={onInputChange}
        />
        <MUITextField
          label="Representante"
          defaultValue={businesses?.owner_name}
          type="text"
          name="owner_name"
          onChange={onInputChange}
        />

        <MUITextField
          label="RFC"
          defaultValue={businesses?.rfc}
          type="text"
          name="rfc"
          onChange={onInputChange}
        />
      </div>
    </ModalComponent>
  );
};

export default BusinessModalView;
