/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import styles section **/
import '../../views/Services/ServicesStyles.scss';

/** Import component section **/

export const StatusColor = ({ title, color }) => {
  const colorItem = color ? color : '#ffff';
  return (
    <div className="services_color-cell_column">
      <div className="services_color-cell_column_wrap">
        <div
          style={{ backgroundColor: colorItem }}
          className="services_color-cell_column_wrap_color"
        />
      </div>
      {title}
    </div>
  );
};

StatusColor.propTypes = {
  row: PropTypes.object
};

export default StatusColor;
