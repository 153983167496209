/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addStatusService,
  updateStatusService
} from '../../../api/services/status-services';

/** Import component section **/
import StatusModalView from './StatusModalView';

const StatusModalComponent = ({
  statusData,
  totalItems,
  onClose,
  onUpdateList
}) => {
  const [status, setStatus] = useState(null);
  const [sequences, setSequences] = useState([]);
  const [dataComplete, setDataComplete] = useState(null);

  useEffect(() => {
    setStatus(statusData);
    const sequenceTotalItem = statusData?.id ? totalItems : totalItems + 1;
    const sequenceList = Array(sequenceTotalItem)
      .fill()
      .map((_, idx) => {
        return {
          sequence: idx + 1
        };
      });

    setSequences(sequenceList);
  }, [statusData, totalItems]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setStatus((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setStatus((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!status) return;
    let validated = true;
    const title = status?.title?.trim();
    const color = status?.color?.trim();
    if (color === '' || title === '') {
      validated = false;
    }
    setDataComplete(validated);
  }, [status]);

  const handleOnSave = () => {
    if (statusData.id) {
      updateStatus();
    } else {
      createStatus();
    }
  };

  const createStatus = () => {
    console.log(status);
    addStatusService(status)
      .then((response) => {
        toast.success('Status created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Status create error');
      });
  };

  const updateStatus = () => {
    updateStatusService(status)
      .then(() => {
        toast.success('Status updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('Status update error');
      });
  };

  return (
    <StatusModalView
      status={statusData}
      onInputChange={onInputChange}
      onSwitchChange={onSwitchChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
      sequenceList={sequences}
    />
  );
};

export default StatusModalComponent;
