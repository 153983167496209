/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { getDashboardService } from '../../api/services/dashboard-services';

/** Import component section **/
import DashboardView from './DashboardView';

const DashboardComponent = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSummary = useCallback(() => {
    setLoading(true);
    getDashboardService()
      .then((response) => {
        setDashboardData(response?.dashboard);
      })
      .catch((error) => {
        console.error('DashboardComponent - Error requesting list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return (
    <>
      <DashboardView loading={loading} dashboardData={dashboardData} />
    </>
  );
};

export default DashboardComponent;
