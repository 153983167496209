import request from '../api-connector';

export const getServiceHistoryService = ({ service_id }) => {
  const serviceDef = {
    url: 'service/history',
    method: 'get',
    params: { service_id }
  };
  return request(serviceDef);
};

export const getServiceItemHistoryService = ({ service_item_id }) => {
  const serviceDef = {
    url: 'service-item/history',
    method: 'get',
    params: { service_item_id }
  };
  return request(serviceDef);
};
