/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getServiceItemsService,
  deleteServiceItemService
} from '../../api/services/service-item-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import Item from './Item';
import ServiceItemView from './ServiceItemView';

import {
  SERVICES_ROUTE,
  SERVICE_ITEM_HISTORY_ROUTE
} from '../../config/URL_ROUTES';

const ServiceItemModel = {
  id: null,
  name: '',
  description: '',
  service_id: null,
  status_id: null,
  planned_delivery_date: ''
};

const ServiceItemComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [serviceItems, setServiceitems] = useState([]);
  const [selectedtServiceItem, setSelectedtServiceItem] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  let serviceId = useLocation().state?.id;
  let title = useLocation().state?.title;
  let clientName = useLocation().state?.client;

  if (!serviceId) history.push(SERVICES_ROUTE);

  const getServiceItem = useCallback(() => {
    setLoading(true);
    getServiceItemsService({ service_id: serviceId })
      .then((response) => {
        setServiceitems(response?.service_item);
        setOriginalData(response?.service_item);
      })
      .catch((error) => {
        console.error(
          'serviceItemsComponent - Error requesting service Item list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [serviceId]);

  useEffect(() => {
    getServiceItem();
  }, [getServiceItem]);

  const handleOnUpdateList = (selectedtServiceItem) => {
    getServiceItem();
  };

  const handleOnFilterServiceItem = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.reference?.toString().toLowerCase();
        return field1.includes(filteringText);
      });
    }

    setServiceitems(filteredItems);
  };

  const handleOnAddServiceItem = () => {
    setSelectedtServiceItem(Object.assign({}, ServiceItemModel));
    setModalMode('serviceItems');
  };

  const handleOnEditServiceItem = (row) => {
    setSelectedtServiceItem(row);
    setModalMode('serviceItems');
  };

  const handleOnModalClose = () => {
    setSelectedtServiceItem(null);
    setModalMode(null);
  };

  const handleOnDeleteServiceItem = (row) => {
    setSelectedtServiceItem(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteServiceItemService({ service_item_id: selectedtServiceItem.id })
      .then(() => {
        getServiceItem();
        toast.success('Partida de servicio borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar la partida de servicio');
      })
      .finally(() => {
        setSelectedtServiceItem(null);
        setModalMode(null);
      });
  };

  const handleOnHistory = (row) => {
    history.push({
      pathname: SERVICE_ITEM_HISTORY_ROUTE + '/' + row?.id,
      state: {
        id: row?.id,
        title: row?.code,
        client: row?.client
      }
    });
  };

  return (
    <>
      <ServiceItemView
        title={title}
        clientName={clientName}
        loading={loading}
        serviceItems={serviceItems}
        onFilterItem={handleOnFilterServiceItem}
        onAddItem={handleOnAddServiceItem}
        onEditItem={handleOnEditServiceItem}
        onDeleteItem={handleOnDeleteServiceItem}
        onServiceItemHistory={handleOnHistory}
      />
      {selectedtServiceItem && modalMode === 'serviceItems' && (
        <Item
          serviceId={serviceId}
          itemData={selectedtServiceItem}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedtServiceItem && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Partida "
        >
          {'¿Estas seguro que quieres borrar la partida de servicio  ' +
            selectedtServiceItem.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ServiceItemComponent;
