/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  getBusinessesService,
  deleteBusinessService,
  updateActiveBusinessService
} from '../../api/services/businesses-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal/ModalComponent';
import BusinessModal from './BusinessModal';
import BusinessesView from './BusinessesView';

const businessesModel = {
  id: null,
  comercial_name: '',
  owner_email: '',
  owner_name: '',
  rfc: '',
  active: '',
  registered: ''
};

const activeOptionsFilter = [
  { active: 'Activado', id: 1 },
  { active: 'Desactivado', id: 0 }
];

const BusinessesComponent = () => {
  const [originalData, setOriginalData] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedActiveId, setSelectedActiveId] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);

  const getBusinesses = useCallback((activeId) => {
    setLoading(true);
    let params = {
      active: activeId
    };
    getBusinessesService(params)
      .then((response) => {
        const businesArr = response?.business;
        if (businesArr) {
          setBusinesses(businesArr);
          setOriginalData(businesArr);
        }
      })
      .catch((error) => {
        console.error(
          'businessesComponent - Error requesting bissnesses list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  const handleOnUpdateList = (selectedBusiness) => {
    getBusinesses();
  };

  const handleOnFilterBusinesses = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.comercial_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field2 = item.rfc
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field3 = item.Representante?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field4 = item.owner_email
          ?.toString()
          .toLowerCase()
          .includes(filteringText);
        const field5 = item.owner_name
          ?.toString()
          .toLowerCase()
          .includes(filteringText);

        return field1 || field2 || field3 || field4 || field5;
      });
    }

    setBusinesses(filteredItems);
  };

  const handleOnAddBusiness = () => {
    setSelectedBusiness(Object.assign({}, businessesModel));
    setModalMode('businesses');
  };

  const handleOnEditBusiness = (row) => {
    let item = Object.assign({}, row);
    setSelectedBusiness(item);
    setModalMode('businesses');
  };

  const handleOnModalClose = () => {
    setSelectedBusiness(null);
    setModalMode(null);
  };

  const handleOnDeleteBusiness = (row) => {
    setSelectedBusiness(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteBusinessService({ business_id: selectedBusiness.id })
      .then(() => {
        getBusinesses();
        toast.success('Negocio borrado');
      })
      .catch((error) => {
        console.log(error);
        if (error.response_status === 406) {
          toast.error(
            'Error, no se puede borrar el negocio ya que cuenta con una subscripción.'
          );
          return;
        }
        toast.error('Error, no se puede borrar el negocio.');
      })
      .finally(() => {
        setSelectedBusiness(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedBusiness(row);
    setModalMode('active');
  };

  const handleOnSelectedActiveChange = (activeOptions) => {
    const activeId = activeOptions?.value;
    setSelectedActiveId(activeId);
    getBusinesses(activeId);
  };

  const handleOnActiveConfirm = () => {
    var active = selectedBusiness?.active === 0 ? 1 : 0;
    updateActiveBusinessService({
      business_id: selectedBusiness?.id,
      active: active
    })
      .then(() => {
        getBusinesses();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedBusiness(null);
        setModalMode(null);
      });
  };
  return (
    <>
      <BusinessesView
        loading={loading}
        businesses={businesses}
        onFilterBusinesses={handleOnFilterBusinesses}
        onAddBusiness={handleOnAddBusiness}
        onEditBusiness={handleOnEditBusiness}
        onDeleteBusiness={handleOnDeleteBusiness}
        onActiveBusiness={handleOnActiveChange}
        activeOptions={activeOptionsFilter}
        onSelectedActiveChange={handleOnSelectedActiveChange}
        selectedActiveId={selectedActiveId}
      />
      {selectedBusiness && modalMode === 'businesses' && (
        <BusinessModal
          businessesData={selectedBusiness}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedBusiness && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Negocio"
        >
          {'¿Estas seguro que quieres borrar el negocio ' +
            selectedBusiness.comercial_name +
            '?'}
        </Modal>
      )}

      {selectedBusiness && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedBusiness?.active === 0
              ? 'Activar negocio?'
              : 'Desactivar negocio'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedBusiness?.active === 0 ? 'Activar' : 'Desactivar') +
            ' el negocio ' +
            selectedBusiness.owner_email +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default BusinessesComponent;
