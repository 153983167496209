/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';

/** Import helpers section **/
import './ServiceItemStyles.scss';
import { SERVICES_ROUTE } from '../../config/URL_ROUTES';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
const ServiceItemView = ({
  loading,
  serviceItems,
  onFilterItem,
  onAddItem,
  onServiceItemHistory,
  onEditItem,
  onDeleteItem,
  title,
  clientName
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <Tooltip
          title="Historial de la partida"
          children={
            <ClassRoundedIcon
              className="g-row-action-button services_color-item"
              onClick={() => onServiceItemHistory(row)}
            />
          }
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditItem(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteItem(row)}
        />
      </>
    );
  };

  const ColorCell = ({ row }) => {
    const color = row?.color ? row.color : '#ffff';
    return (
      <div className="service_item_color-cell_column">
        <div className="service_item_color-cell_column_wrap">
          <div
            style={{ backgroundColor: color }}
            className="service_item_color-cell_column_wrap_color"
          />
        </div>
        {row.status_title}
      </div>
    );
  };

  let serviceName = serviceItems[0]?.service?.code
    ? 'Partidas_del_servicio_' + serviceItems[0]?.service?.code
    : '';

  const tableColumns = useMemo(
    () => [
      {
        name: 'No. de partida',
        selector: (row) => row?.order,
        sortable: true,
        center: true
      },
      {
        name: 'Partida',
        selector: (row) => row?.name,
        sortable: true,
        center: true
      },
      {
        name: 'Descripción',
        selector: (row) => row?.description,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus',
        cell: (row) => <ColorCell row={row} />,
        selector: (row) => row?.status_title,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: 'Fecha de entrega',
        selector: (row) => row?.planned_delivery_date,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle
        title={'Partidas ' + title + ' cliente: ' + clientName}
        backUrl={SERVICES_ROUTE}
      />
      <Paper className="g-page-header service_item_header">
        <div className="service_item_header_filterby">
          <FilterBy onChange={onFilterItem} />
        </div>
        <Button onClick={onAddItem} startIcon={<AddCircleIcon />}>
          Agregar nueva partida de servicio
        </Button>
      </Paper>

      <DataTable
        data={serviceItems}
        columns={tableColumns}
        loading={loading}
        showDownload
        title={serviceName.trim()}
      />
    </>
  );
};

export default ServiceItemView;
