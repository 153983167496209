/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const LOGIN_ROUTE = '/login';
export const SIGNUPS_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

// Private routes
export const USERS_ROUTE = '/users';
export const ROLE_PERMISSIONS_ROUTE = '/rol-permissions';
export const PERMISSIONS_ROUTE = '/permissions';
export const BUSSINESES_ROUTE = '/businesses';
export const SUBSCRIPTIONS_ROUTE = '/subscriptions';
export const STAFFS_ROUTE = '/staffs';
export const STATUS_ROUTE = '/status';
export const PROFILES_ROUTE = '/profile';
export const SERVICES_ROUTE = '/services';
export const SERVICE_ITEM_ROUTE = '/service-item';
export const TRACKING_ROUTE = '/tracking';
export const SERVICE_HISTORY_ROUTE = '/service/history';
export const SERVICE_ITEM_HISTORY_ROUTE = '/service-item/history';
export const DASHBOARD_ROUTE = '/dashboard';
