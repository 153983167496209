import request from '../api-connector';

export const getBusinessesService = ({ active }) => {
  const serviceDef = {
    url: 'business/list',
    method: 'get',
    params: { active }
  };
  return request(serviceDef);
};

export const addBusinessService = ({
  comercial_name,
  owner_email,
  owner_name,
  rfc,
  active,
  registered
}) => {
  const serviceDef = {
    url: 'business/create',
    method: 'post',
    params: {
      comercial_name,
      owner_email,
      owner_name,
      rfc,
      active,
      registered
    }
  };
  return request(serviceDef);
};

export const getBusinessByIdService = ({ business_id }) => {
  const serviceDef = {
    url: 'business/read',
    method: 'post',
    params: { business_id }
  };
  return request(serviceDef);
};

export const updateBusinessService = ({
  id,
  comercial_name,
  owner_email,
  owner_name,
  rfc,
  active,
  registered
}) => {
  const serviceDef = {
    url: 'business/update',
    method: 'post',
    params: {
      id,
      comercial_name,
      owner_email,
      owner_name,
      rfc,
      active,
      registered
    }
  };
  return request(serviceDef);
};

export const updateActiveBusinessService = ({ business_id, active }) => {
  const serviceDef = {
    url: 'business/update/active',
    method: 'post',
    params: {
      business_id,
      active
    }
  };
  return request(serviceDef);
};

export const deleteBusinessService = ({ business_id }) => {
  const serviceDef = {
    url: 'business/delete',
    method: 'post',
    params: {
      business_id
    }
  };
  return request(serviceDef);
};

export const getBusinessSelectService = () => {
  const serviceDef = {
    url: 'business/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
