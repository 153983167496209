import request from '../api-connector';

export const getSubscriptionsService = ({ business_id }) => {
  const serviceDef = {
    url: 'subscription/list',
    method: 'get',
    params: { business_id }
  };
  return request(serviceDef);
};

export const addSubscriptionService = ({
  active,
  end_date,
  reference,
  start_date,
  business_id
}) => {
  const serviceDef = {
    url: 'subscription/create',
    method: 'post',
    params: {
      active,
      end_date,
      reference,
      start_date,
      business_id
    }
  };
  return request(serviceDef);
};

export const getSubscriptionByIdService = ({ subscription_id }) => {
  const serviceDef = {
    url: 'subscription/read',
    method: 'post',
    params: { subscription_id }
  };
  return request(serviceDef);
};

export const updateSubscriptionService = ({
  id,
  start_date,
  end_date,
  active,
  reference
}) => {
  const serviceDef = {
    url: 'subscription/update',
    method: 'post',
    params: {
      id,
      start_date,
      end_date,
      active,
      reference
    }
  };
  return request(serviceDef);
};

export const updateActiveSubscriptionService = ({
  subscription_id,
  active
}) => {
  const serviceDef = {
    url: 'subscription/update/active',
    method: 'post',
    params: {
      subscription_id,
      active
    }
  };
  return request(serviceDef);
};

export const deleteSubscriptionService = ({ subscription_id }) => {
  const serviceDef = {
    url: 'subscription/delete',
    method: 'post',
    params: {
      subscription_id
    }
  };
  return request(serviceDef);
};

export const sendEmailSubscriptionService = ({ subscription_id }) => {
  const serviceDef = {
    url: 'subscription/send-email',
    method: 'post',
    params: { subscription_id }
  };
  return request(serviceDef);
};
