/** Import react/libraries section **/
import React, { useMemo } from 'react';
/** Import resources section **/
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import Tooltip from '@mui/material/Tooltip';

/** Import helpers section **/
import './ServicesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import StatusColor from '../../components/StatusColor';

const ServicesView = ({
  loading,
  services,
  onTrackingGuide,
  onFilterService,
  onAddService,
  onEditService,
  onDeleteService,
  onServiceItem,
  onHistoryService,
  onSendNotification
}) => {
  const ActionsCell = ({ row }) => {
    return (
      <>
        <Tooltip
          title="Partidas del servicio"
          children={
            <ClassRoundedIcon
              className="g-row-action-button services_color-item"
              onClick={() => onServiceItem(row)}
            />
          }
        />

        <Tooltip
          title="Historial del servicio"
          children={
            <HistoryIcon
              className="g-row-action-button services_color-history"
              onClick={() => onHistoryService(row)}
            />
          }
        />

        <Tooltip
          title="Seguimiento del servicio"
          children={
            <InfoIcon
              className="g-row-action-button services_color-status"
              onClick={() => onTrackingGuide(row)}
            />
          }
        />

        <Tooltip
          title="Envio de email al responsable"
          children={
            <AttachEmailIcon
              className="g-row-action-button services_color-email"
              onClick={() => onSendNotification(row)}
            />
          }
        />

        <Tooltip
          title="Editar servicio"
          children={
            <EditIcon
              className="g-row-action-button"
              onClick={() => onEditService(row)}
            />
          }
        />
        {row?.has_relationships === false && (
          <Tooltip
            title="Eliminar servicio"
            children={
              <DeleteIcon
                className="g-row-action-button delete"
                onClick={() => onDeleteService(row)}
              />
            }
          />
        )}
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Nombre',
        selector: (row) => row?.name,
        sortable: true,
        center: true,
        width: '300px'
      },
      // {
      //   name: 'Codigo',
      //   selector: (row) => row?.code,
      //   sortable: true,
      //   center: true
      // },
      {
        name: 'Responsable',
        selector: (row) => row?.staff_name,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus',
        cell: (row) => (
          <StatusColor color={row?.color} title={row?.status_title} />
        ),
        selector: (row) => row?.status_title,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: 'Cliente',
        selector: (row) => row?.client,
        sortable: true,
        center: true
      },
      // {
      //   name: 'WhatsApp',
      //   selector: (row) => row?.phone,
      //   sortable: true,
      //   center: true
      // },
      {
        name: 'Fecha de creacion',
        selector: (row) => row?.created_at,
        sortable: true,
        center: true
      },
      {
        name: 'Fecha de entrega',
        selector: (row) => row?.planned_delivery_date,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true,
        width: '220px'
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Servicios" />
      <Paper className="g-page-header services_header">
        <div className="services_header_filterby">
          <FilterBy
            placeholder="Cliente, email y responsable"
            onChange={onFilterService}
          />
        </div>
        <Button
          onClick={onAddService}
          disabled={loading}
          startIcon={<AddCircleIcon />}
        >
          Agregar nuevo servicio
        </Button>
      </Paper>

      <DataTable
        data={services}
        columns={tableColumns}
        loading={loading}
        showDownload
        title={'Servicios'}
      />
    </>
  );
};

export default ServicesView;
