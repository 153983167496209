/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  AddServiceItemService,
  updateServiceItemService,
  sendEmailNotificationClientService
} from '../../../api/services/service-item-services';

import { getStatusSelectService } from '../../../api/services/status-services';

/** Import component section **/
import ItemModalView from './ItemModalView';
import Modal from '../../../components/Modal/ModalComponent';

const ItemModalComponent = ({ itemData, serviceId, onClose, onUpdateList }) => {
  const [item, setItem] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);
  const [status, setStatus] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const getStatus = () => {
    getStatusSelectService()
      .then((response) => {
        if (response?.status) setStatus(response?.status);
      })
      .catch((err) => {
        if (err?.response_status)
          toast.error('No se pueden cargar los responsables');
        console.error('Error getting areas: ', err);
      });
  };

  useEffect(() => {
    setItem(itemData);
    getStatus();
  }, [itemData]);

  const onInputChange = (data) => {
    const { name, value } = data;
    setItem((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setItem((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  useEffect(() => {
    if (!item) return;
    let validated = true;
    const itemName = item?.name?.trim();
    const itemStatus = item?.status_id;
    const plannedDate = item?.planned_delivery_date;

    if (itemName === '' || itemStatus == null || plannedDate === '') {
      validated = false;
    }
    setDataComplete(validated);
  }, [item]);

  const handleOnSave = () => {
    if (itemData.id) {
      updateServiceItem();
      if (item.status_id === itemData.status_id) {
        onClose();
      } else {
        setSendEmail(true);
      }
    } else {
      createServiceItem();
    }
  };

  const createServiceItem = () => {
    let params = {
      service_item_id: item.id,
      service_id: serviceId,
      status_id: item.status_id,
      description: item?.description,
      name: item.name,
      planned_delivery_date: item?.planned_delivery_date
    };
    setLoading(true);
    AddServiceItemService(params)
      .then((response) => {
        toast.success('Partida creada');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('ServiceItem create error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateServiceItem = () => {
    let params = {
      service_item_id: item?.id,
      service_id: serviceId,
      status_id: item?.status_id,
      description: item?.description,
      name: item?.name,
      planned_delivery_date: item?.planned_delivery_date
    };
    setLoading(true);
    updateServiceItemService(params)
      .then(() => {
        toast.success('Partida actualizada');
        onUpdateList();
      })
      .catch((error) => {
        toast.warning('ServiceItem update error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEmailConfirmation = () => {
    let params = {
      service_item_id: item?.id
    };
    sendEmailNotificationClientService(params)
      .then(() => {
        toast.success('Correo de actualización enviado');
        onClose();
      })
      .catch((error) => {
        toast.warning('Service email client error');
      });
  };

  return (
    <>
      <ItemModalView
        item={itemData}
        status={status}
        onInputChange={onInputChange}
        onSwitchChange={onSwitchChange}
        onClose={onClose}
        onSave={handleOnSave}
        disabled={!dataComplete}
        loading={loading}
      />
      {sendEmail === true && (
        <Modal
          open={true}
          onClose={onClose}
          onConfirm={onEmailConfirmation}
          title="Actualizacion exitosa"
        >
          {'¿Deseas enviar un email con la informacion actualizada al cliente?'}
        </Modal>
      )}
    </>
  );
};

export default ItemModalComponent;
